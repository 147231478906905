import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '@tr/target-retail';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../../../shared/global';
import { PermissionService } from '../../../shared/services/permission/permission.service';

@Injectable({
    providedIn: 'root'
})
export class TokenvalidationService {
    public static tokenInformation: any = {};
    public static tenantName: string;
    public static token: string;
    public static tenant: string
    public static programguid: string;
    public data: any;
    public static userPermissions: any = {};
    public static dealerBlackBookEnrollment: boolean;
    public static conquestEnrollmentStatus: string;
    public static creativeType: string;

    constructor(@Inject(DOCUMENT) private document: Document, private activeRoute: ActivatedRoute, private http: HttpClient, private dataService: DataService, private permissionService: PermissionService) { }

    public loadOEMTheme() {
        let OEM = TokenvalidationService.tenant;
        const head = this.document.getElementsByTagName('head')[0];
        let themeLink = this.document.getElementById('oem-theme') as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = OEM;
        } else {
            const style = this.document.createElement('link');
            style.id = 'oem-theme';
            style.rel = 'stylesheet';
            style.href = `dist/assets/${OEM}/Stylesheets/main.css`;
            head.appendChild(style);
        }
    }

    public validateAccessToken() {
        var self = this;
        TokenvalidationService.programguid = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&').filter(el => el.toUpperCase().indexOf('PROGRAMGUID') !== -1).toString().substring(12);
        if (window.location.href.indexOf('localhost') !== -1) {
            const trForm = window.parent.document.getElementById('tr-form') as HTMLFormElement | null;
            TokenvalidationService.programguid = trForm.elements["programGuid"].value;
        }
        return new Promise<void>((resolve, reject) => {
            let objClaims: any = {};
            this.http.get("api/account")
                .toPromise()
                .then(
                    async result => {
                        this.data = result;
                        if (this.data.claims.length > 1) {
                            objClaims = TokenvalidationService.setTokenInformation(this.data.claims);
                            objClaims["ProgramGuid"] = TokenvalidationService.programguid;
                            try {
                                await self.getTenantMapping(TokenvalidationService.tenant).then((data: any[]) => {
                                    objClaims['DHOEMId'] = data['dhOemId'];
                                    objClaims['CPOEMID'] = data['cpOemId'];
                                });
                            }
                            catch (error) {
                                console.log(error);
                            }
                            TokenvalidationService.tokenInformation = objClaims;
                            this.dataService.setUserObject(TokenvalidationService.tokenInformation);
                            sessionStorage.setItem('tenant', TokenvalidationService.tenant);
                            GlobalVariable.tenantName = TokenvalidationService.tenant;
                            GlobalVariable.dealerName = objClaims['OrgName'];
                            GlobalVariable.dealerID = objClaims['PartDealerId'];
                            GlobalVariable.orgGuid = objClaims['OrgGuid'];
                            GlobalVariable.userName = objClaims['UserName'];
                            GlobalVariable.culture = objClaims['Culture'];
                            GlobalVariable.userGuid = objClaims['UserGuid'];
                            GlobalVariable.brand = objClaims['brand'];
                            GlobalVariable.role = objClaims['Role'];
                            if (TokenvalidationService.tenant) {
                                try {
                                    TokenvalidationService.userPermissions = GlobalVariable.permissions = null; //await self.permissionService.getUserPermissions(TokenvalidationService.tenant, GlobalVariable.orgGuid, GlobalVariable.userName, 'TargetRetail');
                                    if (!environment.production) console.log(TokenvalidationService.userPermissions);
                                }
                                catch (error) {
                                    console.log(error);
                                }
                            }
                            GlobalVariable.isImpersonated = (objClaims['IsImpersonated'] && objClaims['IsImpersonated'] === "true") ? true : false;
                            if (TokenvalidationService.tokenInformation['Role']) {
                                TokenvalidationService.tokenInformation['Role'].forEach(rl => {
                                    if (!GlobalVariable.isGlobalAdmin) GlobalVariable.isGlobalAdmin = (rl === "GlobalAdmin") ? true : false;
                                });
                            }
                        }
                        resolve();
                        if (!environment.production) console.log(TokenvalidationService.tokenInformation);
                    },
                    msg => {
                        reject("Error in user service" + msg);
                    }
                );

        });
    }

    async getTenantMapping(tenant): Promise<any> {
        return await this.http.get<any>(`${'/tr-api/Tenant/TenantMapping?tenant='}${tenant}`).toPromise();
    }
    async getBlackbookEnrollmentStatus(oemId, partDealerId): Promise<any> {
        return await this.http.post(`${'/tr-analytics-api/Insights/getBlackbookEnrollmentStatus?oemId='}${oemId}&dealerId=${partDealerId}`, '').toPromise();
    }
    public static setTokenInformation(tokenData: any) {
        let tokenDataObject = {};
        tokenDataObject['Role'] = [];
        tokenData.forEach(tokenInfoElement => {
            switch (tokenInfoElement.type) {
                case 'culture': {
                    tokenDataObject['Culture'] = tokenInfoElement.value
                    break;
                }
                case 'org_guid': {
                    tokenDataObject['OrgGuid'] = tokenInfoElement.value
                    break;
                }
                case 'PartDealerId': {
                    tokenDataObject['PartDealerId'] = tokenInfoElement.value
                    break;
                }
                case 'org_name': {
                    tokenDataObject['OrgName'] = tokenInfoElement.value
                    break;
                }
                case 'association_id': {
                    tokenDataObject['association_id'] = tokenInfoElement.value
                    break;
                }
                case 'first_name': {
                    tokenDataObject['FirstName'] = tokenInfoElement.value
                    break;
                }
                case 'last_name': {
                    tokenDataObject['LastName'] = tokenInfoElement.value
                    break;
                }
                case 'name': {
                    tokenDataObject['Name'] = tokenInfoElement.value
                    break;
                }
                case 'username': {
                    tokenDataObject['UserName'] = tokenInfoElement.value
                    break;
                }
                case 'user_id': {
                    tokenDataObject['UserId'] = tokenInfoElement.value
                    break;
                }
                case 'sub': {
                    tokenDataObject['UserGuid'] = tokenInfoElement.value
                    break;
                }
                case 'sitename': {
                    tokenDataObject['OEMId'] = TokenvalidationService.tenantName = tokenInfoElement.value
                    break;
                }
                case 'tenant': {
                    tokenDataObject['OEM'] = TokenvalidationService.tenant = tokenInfoElement.value
                    break;
                }
                case 'brand': {
                    tokenDataObject['brand'] = tokenInfoElement.value
                    break;
                }
                case 'role': {
                    tokenDataObject['Role'].push(tokenInfoElement.value);
                    break;
                }
                case 'is_impersonated': {
                    tokenDataObject['IsImpersonated'] = tokenInfoElement.value;
                    break;
                }
                case 'contract_options': {
                    tokenDataObject['ContractOptions'] = tokenInfoElement.value;
                    break;
                }
                case 'dealer_state_color': {
                    tokenDataObject['DealerStateColor'] = tokenInfoElement.value;
                    break;
                }
                case 'contract_bundle_type': {
                    tokenDataObject['ContractBundleType'] = tokenInfoElement.value;
                    break;
                }
                case 'part_dealer_id': {
                    tokenDataObject['PartDealerId'] = tokenInfoElement.value
                    break;
                }
                case 'contract_condition': {
                    tokenDataObject['ContractCondition'] = tokenInfoElement.value
                    break;
                }
                case 'contract_status': {
                    tokenDataObject['ContractStatus'] = tokenInfoElement.value
                    break;
                }
                case 'dealer_participation': {
                    tokenDataObject['DealerParticipation'] = tokenInfoElement.value
                    break;
                }
            }
        });
        return tokenDataObject;
    }
}
