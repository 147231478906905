export const environment = {
    production: false,
    name: 'prod',
    dataHubOEMS: {
        "NissanTR": "35273",
        "InfinitiTR": "35050",
        "GMTR": "35541",
        "KIATR": "35047",
        "FordTR": "35456"
    },
    conquestEnrollmentConditions: {
        "NissanTR": false,
        "InfinitiTR": false,
        "GMTR": true,
        "KIATR": false,
        "FordTR": true
    },
    webEnrollmentDAMAPI: "https://axis-api-gw.epsilonrms.com/DAMAPI/dam/streamfile?path="
};
