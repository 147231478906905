import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ComponentPermission } from '../../../core/models/permission.model';


@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    campaignData: any;
    private httpClientNoInterceptor: HttpClient;

    constructor(private http: HttpClient, private handler: HttpBackend) {
        this.httpClientNoInterceptor = new HttpClient(handler);
    }
    //obsolete
    async getUserPermissions_old(tenant: string, orgGuid: string, userName: string, subSystemName: string): Promise<any> {
        return await this.http.get<ComponentPermission[]>(`/tr-api/Permissions/Get?Tenant=${tenant}&OrgGuid=${orgGuid}&UserName=${userName}&SubSystemName=${subSystemName}`).toPromise();
    }
    async getUserPermissions(tenant: string, orgGuid: string, userName: string, subSystemName: string): Promise<any> {
        var options = {
            headers: new HttpHeaders({
                'Accept': 'application/json'
            }),
            'responseType': 'json' as 'json'
        }
        return await this.http.get<ComponentPermission[]>(`/admin-api/subsystems/${subSystemName}/${userName}/permissions`, options).toPromise();
    }
}
