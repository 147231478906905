import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsConditionService {
    isTermsConditionAccept;
  constructor(private http: HttpClient) { }

  public getTermsAndConditionFlag(partDealerId: number): Observable<any> {
      return this.http.post(`${'/tr-api/WebEnrollment/Contract?partDealerID='}${partDealerId}&requestType='Contract'`,'');
  }

  setTermsConditionStatus(value: boolean) {
    this.isTermsConditionAccept = value;
  }

  getTermsConditionStatus() {
    return this.isTermsConditionAccept;
  }

  public acceptTermsAndConditions(PartDealerID: number, attributeName: string, attributeValue: string, user: string) {
      return this.http.post(
          `${'/tr-api/WebEnrollment/UpdateContract?PartDealerID='}${PartDealerID}&UpdateAttributes.Name=${attributeName}&UpdateAttributes.Value=${attributeValue}&User=${user}`,'')
  }
}
