<h1 mat-dialog-title>{{resources["tr.error.title"]}}</h1>
<div mat-dialog-content>
	<p *ngIf="data.status">
		<b>Status Code: {{data.status}}</b>
	</p>
	<p>
		{{resources["tr.error.message"]}}
	</p>
	<p class="error-message">
		{{data?.message}}
	</p>
</div>
<div mat-dialog-actions>
	<button mat-raised-button mat-dialog-close color="primary" class='button-primary'>
    Close
  </button>
</div>
