<router-outlet>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#fff"
               type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
</router-outlet>

<!-- Terms and Condition Modal -->
<ng-template #terms_condition let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{resources["tr.terms.condition"]}}</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div [innerHTML]="termsConditionMessage"></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click'); accpetTermsCondition();">{{resources["tr.terms.condition.accept"]}}</button>
    </div>
  </ng-template>


