import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { ErrorDialogService } from '../../../shared/services/error-dialog/error-dialog.service';
import { LoadingDialogService } from '../../../shared/services/loading/loading-dialog.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private errorDialogService: ErrorDialogService,
        private loadingDialogService: LoadingDialogService,
        private spinner: NgxSpinnerService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!request.url.includes('Campaign/Execute')) {
            this.spinner.show();
            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse) => {
                    // this.errorDialogService.openDialog(error.message ?? JSON.stringify(error), error.status);
                    //console.log("Error - Status  : ", error.status, "\n", "Message : ", error.message)
                    return throwError(error);
                }),
                finalize(() => {
                    this.spinner.hide();
                })
            ) as Observable<HttpEvent<any>>;
        }
        else {
            return next.handle(request);
        }
    }
}
