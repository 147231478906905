import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatureRoutingModule } from './feature-routing.module';
import { HighchartsChartModule } from 'highcharts-angular';


@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule, FeatureRoutingModule, NgbModule, HighchartsChartModule],
    exports: [],
  providers: []
})
export class FeatureModule {}
