import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: "", pathMatch: "full", redirectTo: "home" },
    { path: "dashboard", pathMatch: "full", redirectTo: "home" },
    { path: 'home', loadChildren: () => import('./homelib/homelib.module').then(m => m.HomelibModule) },
    { path: 'serviceappointment', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'onlinebrowsing', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'browsinginsights', loadChildren: () => import('./performance/performance.module').then(m => m.PerformanceModule) },
    { path: "reports", loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
    { path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: "aftersales", loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'campaign/:id', loadChildren: () => import('./homelib/homelib.module').then(m => m.HomelibModule) },
    { path: 'campaign', loadChildren: () => import('./campaign-section/campaign-section.module').then(m => m.CampaignSectionModule) },
    { path: 'geo-target', loadChildren: () => import('./geotarget/geotarget.module').then(m => m.GeotargetModule) },
    { path: 'saletrend', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'mleinsights', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'equityandlease', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'consumer', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'financial', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'map', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
    { path: 'awo/:id', loadChildren: () => import('./alwayson-packages/alwayson-packages.module').then(m => m.AlwaysonPackagesModule) },
    { path: 'alwayson/:id', loadChildren: () => import('./alwayson/alwayson.module').then(m => m.AlwaysonModule) },
    { path: 'templates', loadChildren: () => import('./template-library/template-library.module').then(m => m.TemplateLibraryModule) },
    { path: 'template/:id', loadChildren: () => import('./template-library/template-detail/template-detail.module').then(m => m.TemplateDetailModule) },
    { path: 'faq/:id', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeatureRoutingModule { }
