import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { TokenvalidationService } from '../tokenvalidate/tokenvalidation.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    public resourceInformation: any;
    constructor(private activeRoute: ActivatedRoute, private http: HttpClient) { }
    public GetAllResources() {

        return new Promise<void>((resolve, reject) => {
            if (TokenvalidationService.tokenInformation['Culture']) {
                // const headers = { 'X-EA-Tenant': TokenvalidationService.tenant }
                this.http.get<any>(`${'/tr-api/Resource?tenant='}${TokenvalidationService.tenant}&culture=${TokenvalidationService.tokenInformation['Culture']}`)
                    .toPromise()
                    .then(
                        result => { // Success
                            this.resourceInformation = result;
                            resolve();
                        },
                        msg => { // Error
                            reject("Error in token validation" + msg);
                        }
                    );
            }
        });
    }
}
