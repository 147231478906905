import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceService } from '../../../../core/services/resource/resource.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent {
    resources: any;
  constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: { message: string; status?: number }, private resourceService: ResourceService
  ) {
      this.resources = this.resourceService.resourceInformation.Resources;
  }
}
