import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenvalidationService } from './core/services/tokenvalidate/tokenvalidation.service';
import { ResourceService } from './core/services/resource/resource.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { TRCustomersModule, DataService } from '@tr/target-retail';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { FeatureModule } from './feature/feature.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { TokenInterceptor } from './core/interceptor/token/TokenInterceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HighchartsChartModule } from 'highcharts-angular';
export function initializeApp(appInitService: TokenvalidationService, resourceService: ResourceService) {
    return (): Promise<any> => {
        return appInitService.validateAccessToken().then(() => {
            return resourceService.GetAllResources().then(() => {
                appInitService.loadOEMTheme();
            })
        });
    };
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
       // TRCustomersModule,
        NgxSpinnerModule,
        DataTablesModule,
        NgSelectModule,
        CoreModule,
        SharedModule,
        FeatureModule,
        HighchartsChartModule,
        ServiceWorkerModule.register('dist/ngsw-worker.js', { enabled: environment.production }),
        //CommonServicesModule.forRoot({ "serviceUrl": "http://denpendev1.ams.com:20501/" })
    ],
    providers: [
        TokenvalidationService,
        { provide: LOCALE_ID, useValue: navigator.language },
        //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [TokenvalidationService, ResourceService], multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule { }
